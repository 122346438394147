import classNames from "classnames";
import { PropsWithRef, useId } from "react";
import { Controller } from "react-hook-form";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { HBK, useSearchReservationPolicies } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Combobox from "../../ui/Combobox";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input, { Props as InputProps } from "../../ui/Input";
import Panel from "../../ui/Panel";
import Select from "../../ui/Select";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { range } from "../../utils";
import styles from "./Settings.module.css";

interface Props {
  ratePlan: HBK.RatePlan;
}

const ages = range(0, 18);

const Settings = ({ ratePlan }: Props) => {
  return (
    <Panel title="Einstellungen">
      <Form<
        Pick<
          HBK.RatePlanBody,
          | "min_stay_arrival"
          | "max_stay_arrival"
          | "adult_occupancy"
          | "child_occupancy"
        > & {
          stayType: HBK.StayType;
          childrenAllowed: boolean;
          reservationPolicy: HBK.ReservationPolicy;
        }
      >
        defaultValues={{
          childrenAllowed: true,
          stayType: createStayType(
            ratePlan.min_stay_arrival,
            ratePlan.max_stay_arrival,
          ),
          ...ratePlan,
          child_occupancy: {
            ...ratePlan.child_occupancy,
            max_age: ratePlan.adult_occupancy.min_age,
          },
        }}
        disabled={true}
      >
        {({ control, watch, register, formState: { errors } }) => {
          const [childrenAllowed, watchStayType] = watch([
            "childrenAllowed",
            "stayType",
          ]);

          return (
            <>
              <FormField label="Aufenthalt/Nächte">
                {({ labelId }) => (
                  <div className={styles.input}>
                    <Select
                      id={labelId}
                      {...register("stayType", {
                        valueAsNumber: true,
                      })}
                      disabled
                      className={classNames({
                        [styles.select]: watchStayType === "from",
                      })}
                    >
                      {HBK.stayTypes.map((type) => (
                        <option key={type} value={type}>
                          {i18n.ratePlans.stayType[type]}
                        </option>
                      ))}
                    </Select>
                    {watchStayType === "from_to" && (
                      <Range
                        labelId={labelId}
                        disabled
                        type="number"
                        startProps={{
                          ...register("min_stay_arrival", {}),
                          isInvalid: !!errors.adult_occupancy?.min,
                        }}
                        endProps={{
                          ...register("max_stay_arrival", {}),
                          isInvalid: !!errors.adult_occupancy?.max,
                        }}
                      />
                    )}
                    {watchStayType === "exactly" && (
                      <Input
                        id={labelId}
                        type="number"
                        {...register("min_stay_arrival", {
                          max: 100,
                          min: 5,
                          valueAsNumber: true,
                        })}
                        inputMode="numeric"
                        disabled
                      />
                    )}
                    {watchStayType === "from" && (
                      <Input
                        id={labelId}
                        type="number"
                        {...register("min_stay_arrival", {
                          max: 100,
                          min: 5,
                          valueAsNumber: true,
                        })}
                        inputMode="numeric"
                        disabled
                      />
                    )}
                  </div>
                )}
              </FormField>
              <FormField label="Anzahl Erwachsene">
                {({ labelId }) => (
                  <Range
                    labelId={labelId}
                    type="number"
                    startProps={{
                      ...register("adult_occupancy.min", {}),
                      isInvalid: !!errors.adult_occupancy?.min,
                    }}
                    endProps={{
                      ...register("adult_occupancy.max", {}),
                      isInvalid: !!errors.adult_occupancy?.max,
                    }}
                    disabled
                  />
                )}
              </FormField>
              <FormField label="Erwachsen ab">
                {({ labelId }) => (
                  <Select
                    id={labelId}
                    {...register("adult_occupancy.min_age", {
                      valueAsNumber: true,
                    })}
                    disabled
                  >
                    {ages.map((age) => (
                      <option key={age} value={age}>
                        {i18n.age(age)}
                      </option>
                    ))}
                  </Select>
                )}
              </FormField>
              <FormField label="Kinder erlaubt">
                {({ labelId }) => (
                  <Controller
                    name="childrenAllowed"
                    control={control}
                    render={({ field }) => (
                      <ToggleSwitch
                        labelId={labelId}
                        checked={field.value}
                        onChange={field.onChange}
                        disabled
                      />
                    )}
                  />
                )}
              </FormField>
              {childrenAllowed && (
                <>
                  <FormField label="Anzahl Kinder">
                    {({ labelId }) => (
                      <Range
                        labelId={labelId}
                        type="number"
                        startProps={{
                          ...register("child_occupancy.min", {}),
                          isInvalid: !!errors.child_occupancy?.min,
                        }}
                        endProps={{
                          ...register("child_occupancy.max", {}),
                          isInvalid: !!errors.child_occupancy?.max,
                        }}
                        disabled
                      />
                    )}
                  </FormField>
                  <FormField label="Mindes- und Maximaltalter Kinder">
                    {({ labelId }) => {
                      const toLabelId = useId();
                      return (
                        <div className={styles.range}>
                          <div className={styles.input}>
                            <Select
                              id={labelId}
                              {...register("child_occupancy.min_age", {
                                valueAsNumber: true,
                              })}
                              disabled
                              className={styles.select}
                            >
                              {ages.map((age) => (
                                <option key={age} value={age}>
                                  {i18n.ratePlans.age(age)}
                                </option>
                              ))}
                            </Select>
                          </div>
                          <label htmlFor={toLabelId}>bis</label>
                          <div className={styles.input}>
                            <Select
                              id={labelId}
                              {...register("child_occupancy.max_age", {
                                valueAsNumber: true,
                              })}
                              className={styles.select}
                              disabled
                            >
                              {ages.map((age) => (
                                <option key={age} value={age}>
                                  {i18n.ratePlans.maxAge(age)}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      );
                    }}
                  </FormField>
                  <FormField label="Reservierungsbedingung">
                    {({ labelId }) => (
                      <Controller
                        name="reservationPolicy"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Combobox.Async
                            labelId={labelId}
                            fetcher={(i) =>
                              useSearchReservationPolicies(
                                usePropertyIdParam(),
                                i,
                              )
                            }
                            value={field.value}
                            displayValue={(value) => `${value.name}`}
                            onChange={field.onChange}
                            placeholder="Suche"
                          />
                        )}
                      />
                    )}
                  </FormField>
                </>
              )}
            </>
          );
        }}
      </Form>
    </Panel>
  );
};

interface RangeProps {
  labelId: string;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute | undefined;
  startProps?: PropsWithRef<InputProps>;
  endProps?: PropsWithRef<InputProps>;
}

const Range = ({
  labelId,
  disabled = false,
  type,
  startProps,
  endProps,
}: RangeProps) => {
  const toLabelId = useId();

  return (
    <div className={styles.range}>
      <div className={styles.input}>
        <Input id={labelId} disabled={disabled} {...startProps} type={type} />
      </div>
      <label htmlFor={toLabelId}>bis</label>
      <div className={styles.input}>
        <Input id={toLabelId} disabled={disabled} {...endProps} type={type} />
      </div>
    </div>
  );
};

const createStayType = (
  minStayArival: number,
  maxStayArrival: number,
): HBK.StayType => {
  if (minStayArival >= 0 && maxStayArrival === 0) return "from";
  if (minStayArival === maxStayArrival) return "exactly";
  return "from_to";
};

export default Settings;
