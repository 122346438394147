import { ReactNode, useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { useWidgetIdParam } from "../../hooks/useRouterParam";
import { HBK, patchWidget, useWidget } from "../../http/dashboardApi";
import EmptyPage from "../../pages/EmptyPage";
import WidgetOverview from "./WidgetOverview";
import WidgetPropertySelection from "./WidgetPropertySelection";
import WidgetSettings from "./WidgetSettings";

interface Props {
  widget: HBK.WidgetResponse;
  portal?: HBK.WidgetPortal;
  property: HBK.WidgetProperty | undefined;
  isLoading: boolean;
  controls?: ReactNode;
  mutate: (widget: HBK.Widget) => void;
}

const WidgetRoutes = ({
  widget,
  portal,
  property,
  isLoading,
  controls,
  mutate,
}: Props) => {
  const dispatch = useContext(NotificationContext);
  return (
    <Routes>
      <Route
        index
        element={
          <WidgetOverview
            widget={widget}
            portal={portal}
            property={property}
            isLoading={isLoading}
            controls={controls}
          />
        }
      />
      <Route
        path="edit"
        element={
          property && (
            <WidgetSettings
              initialWidget={widget}
              propertyId={property.id}
              onSubmit={(body) =>
                patchWidget(widget.id, body)
                  .then(
                    (widget) => (
                      dispatch(showSuccessNotification()), mutate(widget)
                    ),
                  )
                  .catch((error: Error) => {
                    dispatch(showErrorNotification(error));
                  })
              }
            />
          )
        }
      />
    </Routes>
  );
};

interface PropertyWidgetProps {
  widget: HBK.PropertyWidget;
  isLoading: boolean;
  mutate: (widget: HBK.PropertyWidget) => void;
}

const PropertyWidget = ({ widget, isLoading, mutate }: PropertyWidgetProps) => (
  <WidgetRoutes
    widget={widget}
    property={widget.property}
    isLoading={isLoading}
    mutate={(w) => mutate({ ...w, property: widget.property })}
  />
);

interface PortalWidgetProps {
  widget: HBK.PortalWidget;
  isLoading: boolean;
  mutate: (widget: HBK.PortalWidget) => void;
}

const PortalWidget = ({ widget, isLoading, mutate }: PortalWidgetProps) => {
  const [property, setProperty] = useState<HBK.WidgetProperty>();

  return (
    <WidgetRoutes
      widget={widget}
      portal={widget.portal}
      property={property}
      isLoading={isLoading}
      mutate={(w) => mutate({ ...w, portal: widget.portal })}
      controls={
        <WidgetPropertySelection
          value={property}
          portalId={widget.portal.id}
          onChange={setProperty}
        />
      }
    />
  );
};

const isPropertyWidget = (r: HBK.WidgetResponse): r is HBK.PropertyWidget => {
  return (r as HBK.PropertyWidget).property !== undefined;
};

export const isPortalWidget = (
  r: HBK.WidgetResponse,
): r is HBK.PortalWidget => {
  return (r as HBK.PortalWidget).portal !== undefined;
};

const Widget = () => {
  const widgetId = useWidgetIdParam();
  const { data: widget, isLoading, error, mutate } = useWidget(widgetId);

  if (error) {
    return (
      <EmptyPage title="Nicht gefunden">
        Das gesuchte Widget konnte leider nicht gefunden werden.
      </EmptyPage>
    );
  }

  if (!widget) return null;

  return isPropertyWidget(widget) ? (
    <PropertyWidget widget={widget} isLoading={isLoading} mutate={mutate} />
  ) : (
    <PortalWidget widget={widget} isLoading={isLoading} mutate={mutate} />
  );
};

export default Widget;
