import classNames from "classnames";
import leaflet from "leaflet";
import { ReactNode, useState } from "react";
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useFormContext,
} from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { Admin } from "../../../http/adminApi";
import { HBK, useAddressSearch } from "../../../http/dashboardApi";
import i18n from "../../../i18n";
import Combobox from "../../../ui/Combobox";
import FormField from "../../../ui/FormField";
import FormFieldTranslations from "../../../ui/FormFieldTranslations";
import Input from "../../../ui/Input";
import LeafletMap from "../../../ui/LeafletMap";
import LocationPin, { LocationPinInfo } from "../../../ui/LocationPin";
import Radio from "../../../ui/Radio";
import { formatClassification, propertyRatings } from "../../../utils";
import PropertyRating from "../../property/PropertyRating";
import styles from "./NewPropertyFormFields.module.css";

interface Props {
  children: (props: {
    register: UseFormRegister<Admin.CreateProperty>;
    control: Control<Admin.CreateProperty>;
    errors: FieldErrors<Admin.CreateProperty>;
    watch: UseFormWatch<Admin.CreateProperty>;
    setValue: UseFormSetValue<Admin.CreateProperty>;
    getValues: UseFormGetValues<Admin.CreateProperty>;
  }) => ReactNode;
}

const NewPropertyFormFields = ({ children }: Props) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext<Admin.CreateProperty>();

  return children({
    register,
    control,
    errors,
    watch,
    setValue,
    getValues,
  });
};

interface FieldProps {
  label: string;
  description?: ReactNode;
  helpText?: ReactNode;
  children:
    | ReactNode
    | ((props: {
        labelId: string;
        isOptional: boolean;
        disabled: boolean;
        required: boolean;
        isInvalid: boolean;
      }) => ReactNode);
  error?: FieldError | undefined;
}

const Field = ({
  label,
  description,
  helpText,
  children,
  error,
}: FieldProps) => (
  <FormField
    label={label}
    description={description}
    helpText={helpText}
    direction="column"
    error={error}
    className={styles.formField}
    rightColumn={{ className: styles.rightColumn }}
  >
    {(props) => (typeof children === "function" ? children(props) : children)}
  </FormField>
);

const Name = () => (
  <NewPropertyFormFields>
    {({ register, errors }) => (
      <Field label="Wie lautet der Name Ihrer Unterkunft?" error={errors.name}>
        {({ required, labelId, isInvalid }) => (
          <Input
            id={labelId}
            type="text"
            {...register("name", {
              required,
            })}
            isInvalid={isInvalid}
          />
        )}
      </Field>
    )}
  </NewPropertyFormFields>
);

const Category = () => (
  <NewPropertyFormFields>
    {({ register, errors }) => (
      <Field
        label="Wählen Sie die Kategorie Ihrer Unterkunft"
        description="Die Kategorie muss mit der Lizenz übereinstimmen."
        error={errors.name}
      >
        {({ required, isInvalid }) =>
          HBK.propertyCategories.map((c) => (
            <Radio
              key={c}
              label={i18n.property.category[c]}
              {...register("category", {
                required,
              })}
              value={c}
              isInvalid={isInvalid}
            />
          ))
        }
      </Field>
    )}
  </NewPropertyFormFields>
);

const Rating = () => (
  <NewPropertyFormFields>
    {({ register, watch, errors }) => (
      <Field
        label="Welche Klassifizierung hat Ihre Unterkunft?"
        description="Die Klassifizierung muss mit der Lizenz übereinstimmen."
        error={errors.category}
      >
        {({ required, isInvalid }) =>
          propertyRatings.map(([rating, isSuperior]) => {
            const value = formatClassification(rating, isSuperior);
            return (
              <Radio
                key={value}
                label={
                  <PropertyRating
                    category={watch("category")}
                    rating={rating}
                    isSuperior={isSuperior}
                  />
                }
                {...register("classification", {
                  required,
                })}
                value={value}
                isInvalid={isInvalid}
              />
            );
          })
        }
      </Field>
    )}
  </NewPropertyFormFields>
);

const Contact = () => (
  <NewPropertyFormFields>
    {({ register, errors }) => (
      <Field label="Geben Sie Ihre Kontaktinformationen ein">
        <Field label="E-Mail" error={errors.email}>
          {({ labelId, required, isInvalid }) => (
            <Input
              id={labelId}
              type="text"
              placeholder="example@example.com"
              {...register("email", {
                required,
                validate: (value) =>
                  isEmail(value) || "Die E-Mail-Adresse ist nicht gültig.",
              })}
              isInvalid={isInvalid}
            />
          )}
        </Field>
        <Field label="Telefonnummer" error={errors.phoneNumber}>
          {({ labelId, required, isInvalid }) => (
            <Input
              id={labelId}
              type="text"
              placeholder="+39 0471 317840"
              {...register("phoneNumber", {
                required,
              })}
              isInvalid={isInvalid}
            />
          )}
        </Field>
      </Field>
    )}
  </NewPropertyFormFields>
);

const Address = () => (
  <NewPropertyFormFields>
    {({ register, control, errors, getValues }) => {
      const prevAddress = getValues("address");
      return (
        <Field label="Wo befindet sich Ihre Unterkunft?">
          <Field
            label="Ortschaft"
            helpText="z.B. Bozen"
            error={errors?.address?.full_address}
          >
            {({ labelId, required }) => (
              <Controller
                name="address"
                control={control}
                rules={{ required }}
                render={({ field }) => (
                  <Combobox.Async
                    labelId={labelId}
                    fetcher={useAddressSearch}
                    value={field.value}
                    displayValue={(address) => address.full_address}
                    onChange={(value) => {
                      if (prevAddress) {
                        const add: HBK.Address = {
                          ...value,
                          latitude: prevAddress.latitude,
                          longitude: prevAddress.longitude,
                        };
                        field.onChange(add);
                      } else {
                        field.onChange(value);
                      }
                    }}
                    placeholder="Nach Ortschaft suchen..."
                  />
                )}
              />
            )}
          </Field>
          <Field label="Straße">
            {({ labelId }) => (
              <FormFieldTranslations
                languages={["de", "it"]}
                errors={errors.address?.street}
                labelId={labelId}
              >
                {({ language, isInvalid, labelId }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    {...register(`address.street.${language}`, {
                      required: true,
                    })}
                  />
                )}
              </FormFieldTranslations>
            )}
          </Field>
        </Field>
      );
    }}
  </NewPropertyFormFields>
);

const initialLocationPinState: PinState = {
  showPin: true,
  showPinInfo: true,
};

interface PinState {
  showPin: boolean;
  showPinInfo: boolean;
}

const getLeafletLatLng = (c: HBK.Coordinates) =>
  leaflet.latLng(c.latitude, c.longitude);

const Map = () => (
  <NewPropertyFormFields>
    {({ watch, setValue }) => {
      const address = watch("address");

      const [pinState, setPinState] = useState<PinState>(
        initialLocationPinState,
      );
      const [position] = useState<leaflet.LatLng>(getLeafletLatLng(address));

      return (
        <div className={styles.mapWrapper}>
          <h2 className={styles.label}>
            Ist die Stecknadel an der richtigen Stelle?
          </h2>
          <LeafletMap
            position={position}
            className={styles.mapContainer}
            zoom={18}
            isPositioning={pinState.showPin}
            onMove={() =>
              setPinState({
                ...pinState,
                showPinInfo: false,
              })
            }
            onPositionChange={(latLng) => {
              setValue("address.latitude", latLng.lat);
              setValue("address.longitude", latLng.lng);
              setPinState({
                ...pinState,
                showPinInfo: true,
              });
            }}
          />
          <LocationPin
            className={classNames(styles.location, styles.pin)}
            disabled={true}
            onClick={() =>
              setPinState({
                showPin: false,
                showPinInfo: false,
              })
            }
          >
            {`${address.street.de}, ${address.full_address}`}
          </LocationPin>
          {pinState.showPinInfo && (
            <LocationPinInfo
              className={classNames(styles.location, styles.info)}
            >
              Verschieben Sie die Karte, um die Stecknadel richtig zu
              positionieren
            </LocationPinInfo>
          )}
        </div>
      );
    }}
  </NewPropertyFormFields>
);

NewPropertyFormFields.Name = Name;
NewPropertyFormFields.Category = Category;
NewPropertyFormFields.Rating = Rating;
NewPropertyFormFields.Contact = Contact;
NewPropertyFormFields.Address = Address;
NewPropertyFormFields.Map = Map;

export default NewPropertyFormFields;
