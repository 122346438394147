import { PropsWithChildren } from "react";
import ErrorImage from "../assets/error.svg";
import Badge from "../ui/Badge";
import styles from "./EmptyPage.module.css";

type Props = PropsWithChildren<{
  title: string;
  image?: string;
  error?: Error;
  showContactInformation?: boolean;
}>;

const EmptyPage = ({
  title,
  image = ErrorImage,
  children,
  error,
  showContactInformation,
}: Props) => {
  return (
    <div className={styles.emptyPage}>
      <div className={styles.title}>{title}</div>
      <div>{children}</div>
      {error && (
        <Badge type="danger" className={styles.error}>
          {error?.message}
        </Badge>
      )}
      {image && <img src={image} className={styles.image} alt={title} />}
      {showContactInformation && (
        <address className={styles.address}>
          <strong>HGV Service</strong>
          <br />
          Schlachthofstraße 59, 39100 Bozen
          <br />
          Telefon: <a href="tel:+390471317700">0471 317 700</a> | E-Mail:{" "}
          <a href="mailto:info@hgv.it">info@hgv.it</a>
        </address>
      )}
    </div>
  );
};

export default EmptyPage;
