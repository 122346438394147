import { useContext } from "react";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../context/notificationContext";
import { Admin, getLTSHotel } from "../../../http/adminApi";
import { BackButton } from "../../../ui/Button";
import Form from "../../../ui/Form";
import FormField from "../../../ui/FormField";
import Input from "../../../ui/Input";
import Panel from "../../../ui/Panel";
import { formatClassification } from "../../../utils";

interface Props {
  onSubmit: (property: Admin.CreateProperty) => void;
}

const LTSImport = ({ onSubmit }: Props) => {
  const dispatch = useContext(NotificationContext);

  return (
    <Panel
      title="Unterkunft importieren"
      description={
        <>
          Ermöglicht das Importieren von Unterkunftsdaten aus dem{" "}
          <a href="https://tic.lts.it/ticweb/index.phtml" target="_blank">
            LTS TIC-Web
          </a>
          .
        </>
      }
    >
      <Form<Admin.LTSConnectionBody>
        onSubmit={(data) =>
          getLTSHotel(data.id)
            .then((prop) => {
              onSubmit({
                ...prop,
                classification: formatClassification(
                  prop.rating,
                  prop.is_superior,
                ),
              }),
                dispatch(
                  showSuccessNotification(
                    "Unterkunftsdaten wurden erfolgreich aus dem LTS TIC-Web importiert.",
                  ),
                );
            })
            .catch((error: Error) => {
              dispatch(showErrorNotification(error));
            })
        }
        secondaryButton={<BackButton />}
      >
        {({ register, formState: { errors }, disabled }) => (
          <FormField
            label="RID"
            helpText="Die RID wird benötigt, um die Unterkunft im LTS Datenbestand zu identifizieren."
            error={errors.id}
          >
            {({ labelId, required, isInvalid }) => (
              <Input
                id={labelId}
                disabled={disabled}
                type="text"
                placeholder="z.B. F6812D1D08F34FA396FFB1E456071C27"
                {...register("id", {
                  required,
                })}
                isInvalid={isInvalid}
              />
            )}
          </FormField>
        )}
      </Form>
    </Panel>
  );
};

export default LTSImport;
