import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { HBK, postPropertyReservationPolicy } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import Form from "../../ui/Form";
import ReservationPolicyFormFields from "./ReservationPolicyFormFields";

const CreateReservationPolicy = () => {
  const propertyId = usePropertyIdParam();
  const dispatch = useContext(NotificationContext);
  const navigate = useNavigate();

  return (
    <Page title="Reservierungsbedingung erstellen" backLink={true}>
      <Form<HBK.ReservationPolicyBody>
        defaultValues={{
          security: "deposit",
          deposit_percentage: 30,
          cancellation_policies: [
            { start_day: null, end_day: 0, fee_percentage: 30 },
          ],
        }}
        onSubmit={(values) =>
          postPropertyReservationPolicy(propertyId, values)
            .then((res) => {
              navigate(`../${res.id}`);
              dispatch(
                showSuccessNotification(
                  "Reservierungsbedingung wurde erfolgreich erstellt.",
                ),
              );
            })
            .catch((error: Error) => dispatch(showErrorNotification(error)))
        }
      >
        {(props) => (
          <ReservationPolicyFormFields {...props} isReadonly={false} />
        )}
      </Form>
    </Page>
  );
};

export default CreateReservationPolicy;
