import useToggle from "../../hooks/useToggle";
import i18n from "../../i18n";
import Button from "../../ui/Button";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Modal from "../../ui/Modal";
import NumberRange from "../../ui/NumberRange";
import Select from "../../ui/Select";
import { range } from "../../utils";

interface Props {
  title: string;
  onSubmit: (codes: string[]) => void;
}

interface FormValues {
  mode: InputMode;
  range: { from: number; to: number };
  code: string;
}

const inputModes = ["single", "range"] as const;
type InputMode = (typeof inputModes)[number];

const AddRoomsModal = ({ title, onSubmit }: Props) => {
  const modal = useToggle();

  return (
    <>
      <Button
        layout="hollow"
        buttonProps={{
          onClick: modal.open,
        }}
      >
        {title}
      </Button>
      {modal.isOpen && (
        <Modal<FormValues>
          title="Zimmernummer hinzufügen"
          submitText="Hinzufügen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          isScrollable={true}
          defaultValues={{
            mode: "single",
            range: {
              from: 1,
              to: 10,
            },
            code: "",
          }}
          onSubmit={(values) => (
            values.mode === "single"
              ? onSubmit([values.code])
              : onSubmit(range(values.range.from, values.range.to).map(String)),
            modal.close()
          )}
        >
          {({ watch, register, getValues, formState: { errors } }) => {
            const { mode } = watch();
            return (
              <>
                <FormField label="Eingabemodus" direction="column">
                  {({ labelId }) => (
                    <Select id={labelId} {...register("mode")}>
                      {inputModes.map((m) => (
                        <option key={m} value={m}>
                          {i18n.room.inputMode[m]}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormField>
                {mode === "single" ? (
                  <FormField
                    label="Zimmercode"
                    direction="column"
                    error={errors.code}
                  >
                    {({ labelId, isInvalid }) => (
                      <Input
                        id={labelId}
                        type="text"
                        placeholder="z.B. 101"
                        {...register("code", {
                          required: mode === "single",
                        })}
                        isInvalid={isInvalid}
                      />
                    )}
                  </FormField>
                ) : (
                  <FormField
                    label="Zimmernummern"
                    direction="column"
                    error={errors.range?.from ?? errors.range?.to}
                  >
                    {({ labelId }) => (
                      <NumberRange
                        labelId={labelId}
                        fromProps={{
                          ...register("range.from", {
                            required: mode === "range",
                            min: 1,
                            valueAsNumber: true,
                            validate: (value) =>
                              value <= getValues("range.to") &&
                              getValues("range.to") - value <= 50,
                          }),
                          isInvalid: !!errors.range?.from,
                        }}
                        toProps={{
                          ...register("range.to", {
                            required: true,
                            min: 1,
                            valueAsNumber: true,
                            validate: (value) =>
                              value >= getValues("range.from"),
                          }),
                          isInvalid: !!errors.range?.to,
                        }}
                      />
                    )}
                  </FormField>
                )}
              </>
            );
          }}
        </Modal>
      )}
    </>
  );
};

export default AddRoomsModal;
