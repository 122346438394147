import EmptyPage from "./EmptyPage";
import WelcomeImage from "../assets/welcome.svg";

const WelcomePage = () => (
  <EmptyPage
    title="Willkommen bei HGV Booking"
    image={WelcomeImage}
    showContactInformation={true}
  >
    <p>
      Es sieht so aus, als hätten Sie keine Berechtigung auf diese Applikation
      zuzugreifen.
      <br />
      Wenden Sie sich an Ihren HGV Ansprechpartner, um weitere Informationen zu
      erhalten.
    </p>
  </EmptyPage>
);

export default WelcomePage;
