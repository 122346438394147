import { useContext, useId, useMemo } from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import useToggle from "../../hooks/useToggle";
import {
  HBK,
  deleteRateplan,
  postPropertyRateplan,
  useRatePlans,
  useSearchChannelRateplans,
} from "../../http/dashboardApi";
import Page from "../../pages/Page";
import { AddButton } from "../../ui/Button";
import Combobox from "../../ui/Combobox";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import DateTimeField from "../../ui/DateTimeField";
import Icon from "../../ui/Icon";
import Modal from "../../ui/Modal";
import Table from "../../ui/Table";
import SvgDelete from "../../ui/icon/Delete.svg?react";
import SvgLink from "../../ui/icon/Link.svg?react";
import SvgLinkOff from "../../ui/icon/LinkOff.svg?react";
import styles from "./RatePlans.module.css";

interface Props {
  propertyId: number;
}

interface RatePlanById {
  [key: string]: HBK.RatePlan[];
}

const RatePlans = ({ propertyId }: Props) => {
  const filters = useListFilters();
  const dispatch = useContext(NotificationContext);

  const { data, isValidating, mutate } = useRatePlans(
    propertyId,
    filters.state,
  );

  const activeRatePlans = useMemo(
    () =>
      data?.rate_plans.reduce<RatePlanById>(
        (curr, av) => ({
          ...curr,
          [av.channel_code]: [...(curr[av.channel_code] ?? []), av],
        }),
        {},
      ),
    [data?.rate_plans],
  );

  const ratePlans = data?.rate_plans ?? [];

  return (
    <Page
      title="Ratenpläne"
      controls={
        <AddRatePlan
          propertyId={propertyId}
          activeRatePlans={activeRatePlans ?? {}}
          onSubmit={(values) =>
            postPropertyRateplan(propertyId, values)
              .then(() => {
                mutate();
                dispatch(showSuccessNotification());
              })
              .catch((error: Error) => dispatch(showErrorNotification(error)))
          }
        />
      }
    >
      <Table
        head={
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>PMS-Ratenplan</th>
            <th>Reservierungsbedingung</th>
            <th>Ertstellt am</th>
            <th />
          </tr>
        }
        isValidating={isValidating}
        total={data?.total ?? 0}
        filters={filters}
        body={({ styles }) =>
          ratePlans.map((r) => {
            return (
              <tr key={r.id}>
                <td>{r.code}</td>
                <td>
                  <Link to={r.id}>{r.title.de}</Link>
                </td>
                <td>{r.channel_title.de}</td>
                <td>-</td>
                <td>
                  <DateTimeField dateTime={r.created_at} />
                </td>
                <td className={styles.alignRight}>
                  <Confirm
                    title="Ratenplan löschen"
                    description={
                      <p>Wollen Sie den Ratenplan wirklich löschen?</p>
                    }
                    onConfirm={() => deleteRateplan(r.id).then(() => mutate())}
                  >
                    {({ open }) => (
                      <Controls>
                        <Controls.Group>
                          <Controls.Link to={`${r.id}/settings`}>
                            Einstellungen
                          </Controls.Link>
                          <Controls.Link to={`${r.id}/photos`}>
                            Fotos
                          </Controls.Link>
                          <Controls.Link to={`${r.id}/description`}>
                            Beschreibung
                          </Controls.Link>
                          <Controls.Link to={`${r.id}/pms-codes`}>
                            PMS Codes
                          </Controls.Link>
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Item
                            glyph={SvgDelete}
                            onClick={open}
                            type="danger"
                          >
                            Ratenplan löschen
                          </Controls.Item>
                        </Controls.Group>
                      </Controls>
                    )}
                  </Confirm>
                </td>
              </tr>
            );
          })
        }
      />
    </Page>
  );
};

interface AddRatePlanProps {
  propertyId: number;
  activeRatePlans: RatePlanById;
  onSubmit: (ratePlan: HBK.RatePlanBody) => void;
}

const AddRatePlan = ({
  propertyId,
  activeRatePlans,
  onSubmit,
}: AddRatePlanProps) => {
  const modal = useToggle();

  return (
    <>
      <AddButton
        buttonProps={{
          onClick: modal.open,
        }}
      >
        Neuen Ratenplan hinzufügen
      </AddButton>
      {modal.isOpen && (
        <Modal<{ ratePlan: HBK.RatePlanBody }>
          title="PMS Ratenplan auswählen und hinzufügen"
          className={styles.modal}
          defaultValues={{}}
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={(value) => (onSubmit(value.ratePlan), modal.close())}
        >
          {({ control }) => {
            const id = useId();

            return (
              <Controller
                name="ratePlan"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Combobox.Async
                    labelId={id}
                    fetcher={(i) => useSearchChannelRateplans(propertyId, i)}
                    value={field.value}
                    displayValue={(ratePlan) => ratePlan.title?.de ?? ""}
                    displayOption={(e) => (
                      <Row
                        ratePlan={e}
                        exists={activeRatePlans[e.channel_code] !== undefined}
                      />
                    )}
                    onChange={field.onChange}
                    placeholder="Suche"
                  />
                )}
              />
            );
          }}
        </Modal>
      )}
    </>
  );
};

interface RowProps {
  ratePlan: HBK.RatePlanBody;
  exists: boolean;
}

const Row = ({ ratePlan, exists }: RowProps) => (
  <div className={styles.row}>
    <div className={styles.title}>
      {ratePlan.title.de}
      <span className={styles.code}>({ratePlan.channel_code})</span>
    </div>
    <Icon glyph={exists ? SvgLink : SvgLinkOff} />
  </div>
);

export default RatePlans;
