import { useContext } from "react";
import styles from "./Invitations.module.css";
import InvitationImage from "./assets/subscribe.svg";
import { useAuth } from "./context/authContext";
import NotificationContext, {
  showErrorNotification,
} from "./context/notificationContext";
import useListFilters from "./hooks/useListFilters";
import {
  acceptInvitation,
  declineInvitation,
  useInvitations,
} from "./http/dashboardApi";
import Page from "./pages/Page";
import Button from "./ui/Button";
import DateTimeField from "./ui/DateTimeField";
import Table from "./ui/Table";

const Invitations = () => {
  const filters = useListFilters();
  const { data, isValidating, mutate } = useInvitations(filters.state);
  const dispatch = useContext(NotificationContext);
  const auth = useAuth();

  const invitations = data?.invitations ?? [];

  return (
    <Page title="Einladungen" backLink>
      {invitations.length === 0 ? (
        <NoInvitation email={auth.user?.email} />
      ) : (
        <>
          <Table
            description={
              <>
                Hier finden Sie alle ausstehenden Einladungen. Nachdem Sie die
                Einladung zur Zusammenarbeit angenommen haben, haben Sie Zugang
                zu den meisten Funktionen dieser Applikation.
              </>
            }
            head={
              <tr>
                <th>Unterkunft</th>
                <th>Gültig Bis</th>
                <th />
              </tr>
            }
            filters={filters}
            total={data?.total ?? 0}
            isValidating={isValidating}
            body={() =>
              invitations.map((invitation) => {
                return (
                  <tr key={invitation.id}>
                    <td>{invitation.property.name}</td>
                    <td>
                      <DateTimeField dateTime={invitation.expires_at} />
                    </td>
                    <td align="right">
                      <div className={styles.buttons}>
                        <Button
                          layout="hollow"
                          buttonProps={{
                            onClick: () =>
                              declineInvitation(invitation.id)
                                .then(() => {
                                  mutate();
                                })
                                .catch((error: Error) => {
                                  dispatch(showErrorNotification(error));
                                }),
                          }}
                        >
                          Ablehnen
                        </Button>
                        <Button
                          buttonProps={{
                            onClick: () =>
                              acceptInvitation(invitation.id)
                                .then(() => {
                                  mutate();
                                })
                                .catch((error: Error) => {
                                  dispatch(showErrorNotification(error));
                                }),
                          }}
                        >
                          Akzeptieren
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          />
          <div className={styles.noInvitation}>
            <img
              src={InvitationImage}
              className={styles.image}
              alt="Invitation"
            />
          </div>
        </>
      )}
    </Page>
  );
};

interface NoInvitationProps {
  email: string | undefined;
}

const NoInvitation = ({ email }: NoInvitationProps) => {
  return (
    <div className={styles.noInvitation}>
      <div className={styles.noItems}>
        <p className={styles.title}>Es sind keine Elemente vorhanden.</p>
        <span className={styles.text}>
          Wenn Sie hier eine Einladung erwarten, vergewissern Sie sich zuerst,
          dass Sie sie per E-Mail erhalten haben. Wenn Sie keine E-Mail erhalten
          haben, überprüfen Sie Ihren Spam-Ordner und stellen Sie sicher, dass
          der Einladende die E-Mail an die richtige Adresse geschickt hat (
          {email}).
        </span>
      </div>
      <img src={InvitationImage} className={styles.image} alt="Invitation" />
    </div>
  );
};

export default Invitations;
