import { Controller, useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Fieldset from "../../ui/Fieldset";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Select from "../../ui/Select";
import ToggleSwitch from "../../ui/ToggleSwitch";
import BookingFormFields from "./BookingFormFields";
import EnquiryFormFields from "./EnquiryFormFields";

interface Props {
  type: "room" | "special";
  bookable: boolean;
}

const OfferingForm = ({ type, bookable }: Props) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<HBK.Widget.Offering.Settings>();

  const isEnquirable = watch("enquirable");

  return (
    <>
      <FormField label="Layout" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("layout", { required })}>
            {HBK.Widget.Offering.layouts.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.offering.layout[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField
        label="Boxen pro Zeile maximal"
        direction="column"
        error={errors.max_columns}
      >
        {({ required, labelId, isInvalid }) => (
          <Input
            id={labelId}
            type="number"
            max={4}
            min={1}
            placeholder="z.B 4"
            {...register("max_columns", {
              required,
              min: 1,
              max: 4,
              valueAsNumber: true,
            })}
            isInvalid={isInvalid}
          />
        )}
      </FormField>
      <FormField label="Variante" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("display_type", { required })}>
            {HBK.Widget.Offering.displayTypes.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.offering.displayType[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField label="Seitenverhältnis der Bilder" direction="column">
        {({ required, labelId }) => (
          <Select
            id={labelId}
            {...register("image_aspect_ratio", {
              required,
              valueAsNumber: true,
            })}
          >
            {HBK.Widget.Offering.imageAspectRatios.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.offering.imageAspectRatio[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField label="Zeige Ab Preis" alignItemsRight={true}>
        {({ labelId }) => (
          <Controller
            name="show_from_price"
            control={control}
            render={({ field }) => (
              <ToggleSwitch
                labelId={labelId}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )}
      </FormField>
      <FormField label="Anfrage möglich" alignItemsRight={true}>
        {({ labelId }) => (
          <Controller
            name="enquirable"
            control={control}
            render={({ field }) => (
              <ToggleSwitch
                labelId={labelId}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )}
      </FormField>
      {isEnquirable && (
        <Fieldset title="Konfiguration des Anfrageformulars">
          <EnquiryFormFields.Layout register={register} name="enquiry.layout" />
        </Fieldset>
      )}
      <FormField label="Buchung möglich" alignItemsRight={true}>
        {({ labelId }) => (
          <ToggleSwitch labelId={labelId} checked={bookable} disabled={true} />
        )}
      </FormField>
      {!bookable && (
        <span>
          Um die Buchungsmöglichkeit für dieses Widget zur aktivieren, muss
          zuerst das HGV-Buchungswidget erworben werden.
        </span>
      )}
      {bookable && (
        <Fieldset title="Konfiguration der Buchungsstrecke">
          <BookingFormFields.License
            register={register}
            name="booking.license"
          />
          <BookingFormFields.MaxAdults
            register={register}
            name="booking.max_adults"
          />
          <BookingFormFields.MaxChildren
            register={register}
            name="booking.max_children"
          />
          <BookingFormFields.ShowDiscounts
            control={control}
            name="booking.show_discounts"
          />
          {type === "room" && (
            <>
              <BookingFormFields.DefaultRoomPriceList
                register={register}
                name="booking.default_room_price_list"
              />
              <BookingFormFields.OverrideDefaultPriceListTitles
                control={control}
                name="booking.override_default_price_list_titles"
              />
            </>
          )}
        </Fieldset>
      )}
    </>
  );
};

export default OfferingForm;
