import { useFieldArray } from "react-hook-form";
import { useParams } from "react-router";
import usePermissions from "../../hooks/usePermissions";
import { HBK } from "../../http/dashboardApi";
import EmptyPage from "../../pages/EmptyPage";
import LoadingPage from "../../pages/LoadingPage";
import { BackButton } from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { parseBoardNameFromAlpineBitsId } from "./utils";

interface Props {
  ratePlans: HBK.Tomas.MappingRatePlans;
  isLoading: boolean;
  onSubmit: (mapping: HBK.Tomas.MappingRatePlans) => void;
}

const TomasRatePlan = ({ ratePlans, isLoading, onSubmit }: Props) => {
  const { ratePlanId = "" } = useParams<"ratePlanId">();
  const canWrite = usePermissions(HBK.Permission.AdminMetasearchWrite);
  const ratePlan = ratePlans[ratePlanId];

  const basePlan = ratePlan?.base_plan;

  if (isLoading && !basePlan) {
    return <LoadingPage />;
  }

  if (!ratePlan)
    return (
      <EmptyPage title="Nicht gefunden">
        Der gesuchte Ratenplan konnte leider nicht gefunden werden.
      </EmptyPage>
    );

  if (!basePlan) return null;

  return (
    <Panel
      title={ratePlanId}
      description={parseBoardNameFromAlpineBitsId(basePlan.id)}
    >
      <Form<{ ratePlans: HBK.Tomas.RatePlan[] }>
        secondaryButton={<BackButton />}
        defaultValues={{
          ratePlans: ratePlan.rate_plans,
        }}
        onSubmit={(values) => {
          const rp: HBK.Tomas.RatePlans = {
            tomas_id: ratePlanId,
            base_plan: ratePlan.base_plan,
            rate_plans: values.ratePlans,
          };
          onSubmit({
            ...ratePlans,
            [rp.tomas_id]: rp,
          });
        }}
        disabled={!canWrite}
      >
        {({ control, disabled }) => {
          const { fields, update } = useFieldArray({
            name: "ratePlans",
            control,
          });

          return (
            <>
              <FormField
                label="Basis-Ratenplan"
                description={basePlan.id}
                helpText="Zusätzlich ausgewählte Ratenpläne werden als Zuschläge aufgerechnet."
              >
                {basePlan.title}
              </FormField>

              {fields.map((r, index) => (
                <FormField
                  key={r.title}
                  label={r.title}
                  description={r.rate_plan_id}
                  alignItemsRight={true}
                  disabled={disabled}
                >
                  {({ labelId, disabled }) => (
                    <ToggleSwitch
                      labelId={labelId}
                      checked={r.checked}
                      disabled={disabled}
                      onChange={(checked) => update(index, { ...r, checked })}
                    />
                  )}
                </FormField>
              ))}
            </>
          );
        }}
      </Form>
    </Panel>
  );
};

export default TomasRatePlan;
