import { addHours } from "date-fns";
import { Controller } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import Select from "../../ui/Select";
import TimeRangeSelect from "../../ui/TimeRangeSelect";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { formatTime, range } from "../../utils";

const childAges = range(0, 17);
const cutoffHourRange = range(0, 23)
  .map((hour) => addHours(0, hour))
  .reverse();

interface Props {
  initialRules: HBK.HouseRules;
  onSubmit: (rules: HBK.HouseRules) => void;
}

const HouseRules = ({ initialRules, onSubmit }: Props) => {
  return (
    <Panel
      title="Hausregeln"
      description="Gäste müssen Ihren Hausregeln zustimmen, bevor sie buchen."
    >
      <Form<HBK.HouseRules> defaultValues={initialRules} onSubmit={onSubmit}>
        {({ register, formState: { errors }, control, watch }) => {
          const childrenAllowed = watch("children_allowed");
          return (
            <>
              <FormField label="Zeitfenster für den Check-in">
                {({ labelId, required }) => (
                  <TimeRangeSelect
                    labelId={labelId}
                    startProps={{
                      ...register("check_in.start", {
                        required,
                      }),
                      isInvalid: !!errors.check_in?.start,
                    }}
                    endProps={{
                      ...register("check_in.end", {
                        required,
                      }),
                      isInvalid: !!errors.check_in?.end,
                    }}
                  />
                )}
              </FormField>
              <FormField label="Zeitfenster für den Check-out">
                {({ labelId, required }) => (
                  <TimeRangeSelect
                    labelId={labelId}
                    startProps={{
                      ...register("check_out.start", {
                        required,
                      }),
                      isInvalid: !!errors.check_out?.start,
                    }}
                    endProps={{
                      ...register("check_out.end", {
                        required,
                      }),
                      isInvalid: !!errors.check_out?.end,
                    }}
                  />
                )}
              </FormField>
              <FormField label="Kinder erlaubt" error={errors.children_allowed}>
                {({ labelId }) => (
                  <Controller
                    name="children_allowed"
                    control={control}
                    render={({ field }) => (
                      <ToggleSwitch
                        labelId={labelId}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                )}
              </FormField>
              {childrenAllowed && (
                <FormField
                  label="Mindestalter für Kinder"
                  error={errors.children_min_age}
                >
                  {({ labelId }) => (
                    <Select
                      id={labelId}
                      {...register("children_min_age", {
                        valueAsNumber: true,
                      })}
                    >
                      {childAges.map((age) => (
                        <option key={age} value={age}>
                          {i18n.age(age)}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormField>
              )}
              <FormField
                label="Anpassbare Buchungsfrist"
                helpText="Gäste können bis zu dieser Zeit am heutigen Tag buchen."
              >
                {({ labelId }) => (
                  <Select id={labelId} {...register("cutoff_hours")}>
                    {cutoffHourRange.map((t, i) => (
                      <option key={i} value={formatTime(t)}>
                        {`${formatTime(t)} Uhr`}
                      </option>
                    ))}
                  </Select>
                )}
              </FormField>
            </>
          );
        }}
      </Form>
    </Panel>
  );
};

export default HouseRules;
