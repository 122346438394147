import { Link } from "react-router-dom";
import NoAvailabilitesImage from "../../assets/no-availabilities.svg";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { useRoomTypes } from "../../http/dashboardApi";
import EmptyPage from "../../pages/EmptyPage";
import Page from "../../pages/Page";
import Calendar from "./Calendar";

const Availabilities = () => {
  const propertyId = usePropertyIdParam();
  const { data, error, isLoading } = useRoomTypes(propertyId, {
    limit: 100,
  });
  const roomTypes = data?.room_types ?? [];

  return (
    <Page
      title="Verfügbarkeiten"
      description="Der Kalender zeigt die Verfügbarkeiten Ihrer Zimmergruppen in einer Wochen-Ansicht an und wird automatisch über Ihr Hotelprogramm synchronisiert."
    >
      <Calendar propertyId={propertyId} roomTypes={roomTypes} />
      {error && (
        <EmptyPage title="Laden fehlgeschlagen" error={error}>
          Beim Laden der Zimmergruppen ist ein Fehler aufgetreten.
        </EmptyPage>
      )}
      {!error && !isLoading && roomTypes.length === 0 && (
        <EmptyPage title="Keine Zimmergruppen" image={NoAvailabilitesImage}>
          Hier werden die Verfügbarkeiten angezeigt, sobald Sie Ihre ersten{" "}
          <Link to="../room-types">Zimmergruppen</Link> hinzugefügt haben.
        </EmptyPage>
      )}
    </Page>
  );
};

export default Availabilities;
